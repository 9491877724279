












































































import { computed, ref } from '@vue/composition-api';
import { ActionTypes } from '@/store/modules/tools/actions';
import store, { useUserState, useUserActions } from '@/store';
import axios from 'axios';

export default {
  components: {
    ATeleInput: () => import('@/components/atoms/ATeleInput.vue')
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const { user }: any = useUserState(['user']);
    const { fetchUser } = useUserActions(['fetchUser']);
    const phoneNumber: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const formattedNumber = ref('');
    const isPhoneValid = ref(false);
    const verifyNumberDialog = ref(false);
    const phoneVerified = ref(!!user.value?.phoneVerified);
    const isSending = ref(false);
    const isVerifying = ref(false);
    const verificationCode = ref('');

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted && val.valid) {
        formattedNumber.value = `+${val.countryCallingCode}${val.formatted}`;
      } else {
        formattedNumber.value = '';
      }
      isPhoneValid.value = val.valid;
    }
    const phoneValid = computed(() => {
      if (isPhoneValid.value && phoneNumber.value) {
        return true;
      }
      return false;
    });
    const isPhoneCheck = computed(() => {
      const substring = '+1';
      const substring1 = '+';
      if (phoneNumber.value.includes(substring) || phoneNumber.value.includes(substring1)) {
        return false;
      }
      return true;
    });

    async function sendOTP() {
      try {
        isSending.value = true;
        const data = {
          to: formattedNumber.value
        };

        const res = await axios.post(`${process.env.VUE_APP_TWILIO_SERVICE}/send`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (res.data) {
          verifyNumberDialog.value = true;
          if (localStorage.getItem('apollo-token')) {
            await fetchUser(localStorage.getItem('apollo-token'));
          }
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Verification code sent successfully',
            type: 'success',
            isShowSnackbar: true
          });
        }
        isSending.value = false;
      } catch (error) {
        isSending.value = false;
      }
    }

    async function verifyNumber() {
      try {
        isVerifying.value = true;
        const data = {
          to: formattedNumber.value,
          code: verificationCode.value,
          type: user.value ? 'student' : 'anonymous',
          userID: user.value ? user.value?._id : 'anonymous'
        };

        const res = await axios.post(`${process.env.VUE_APP_TWILIO_SERVICE}/verify`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (res.data) {
          verifyNumberDialog.value = false;
          phoneVerified.value = true;
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Phone verified successfully',
            type: 'success',
            isShowSnackbar: true
          });
        }
        isVerifying.value = false;
      } catch (error) {
        isVerifying.value = false;
      }
    }

    return {
      phoneNumber,
      verifyNumber,
      sendOTP,
      verifyNumberDialog,
      isSending,
      phoneVerified,
      getValidation,
      phoneValid,
      isPhoneValid,
      isPhoneCheck,
      isVerifying,
      verificationCode
    };
  }
};
